@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}.ImagePlatformeContact {
  position: relative;
  text-align: center;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size:cover;
  overflow: "hidden";
  display: "flex";
  justify-content: "center";
  align-items: "center";
  background-position: center center;
  }
  
  .TitreContact {
    width: 100%;
    font-size: 1.75rem;
    color: white;
    margin: 0 auto;
    line-height: 1.5;
    animation: fadeIn 1s ease-in-out;
    font-weight: 300;
  }
  .EmailContact{
    width: 100%;
    margin: 0;
    padding: 0;

  }
  .EmailContainer{
    width: 50%;
    margin: 0 auto;
  }
  .LabelContact{
    text-align: center;
  }
  .inputObjet{
    width: 30%;
    margin: 0 auto;
  }
.inputMessage{
  height: 200px;
}
  /* .EmailContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .EmailForm {
    width: 70%;
 margin: 0 auto;
  } */
  .sendemail{
    width: 31%;
    display: block;
    margin: 0px auto;
    background-color :rgb(240, 240, 240);
        color: #000;
    padding: 4px;
  }
/*   
  .EmailForm div {
    margin-bottom: 10px;
  }
  
  .EmailForm input[type="text"],
  .EmailForm textarea {
    width: 100%;
  }
  
  .EmailForm input[type="submit"] {
    width: 20%;
    padding: 10px;
  }
   */