
  .PartenaireAjouter {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .ParteAjouterContainer {
    width: 80%;
    height: 100vh;
    margin: 50px auto;
  }
  .LabelParAdd{
    margin-bottom: 10px;
font-size: 16;
color: rgb(128, 128, 128);

font-weight: 300;
}
  .input-containerPar  {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .select-inputPar ,
  .text-inputPar 
   {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
  }
  
  .select-inputPar:focus, .text-inputPar:focus {
    border: solid 1px transparent;
    outline-color: rgb(36, 233, 233);
    outline-style: solid;
    outline-offset: 0;
}
  .submit-buttonPar  {
    color: #fff;
    border: 2px solid transparent;
    border-radius: 10px;
    background-color: #fdc900;
    font-weight: 400;
    text-align: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  .submit-buttonPar:hover{
    border: solid 2px #6610f2;
    background-color: #FFF;
    color: #000;
  }
    