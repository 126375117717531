@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.ChercheCoach {
  width: 100%;
  margin: 0;
  padding: 0;

}

/* Conteneur centré avec une largeur de 80% */
.cherche {
  width: 80%;
  margin: 0 auto;
  animation: fadeIn 1s ease-in-out;

}

/* Titre principal avec une largeur de 100%, marges, alignement centré, et style de police personnalisé */
.ChercheTitle {
  width: 100%;
  margin-top: 100px;
  text-align: center;
  font-size: 47px;
  color: #275491;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  margin: 100px auto 0 auto;
  font-weight: 500;
}

/* Conteneur flexible centré horizontalement et verticalement, avec un espace entre les éléments */
.cherche-coach {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  gap: 20px;
}
.style-Domaine,.style-cherche{
  width: 30%;
}
.style-gouv{
  width: 15%;
}
/* Styles appliqués aux champs de recherche : Domaines, Coach, Gouvernorat */
.DomainesCherche,
.coachcherche,
.gouvernoratCherche {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  color: rgb(73, 80, 87);
  border: solid 1px #ccc;
  height: 40px;
}
.DomainesCherche:focus,.coachcherche:focus,.gouvernoratCherche:focus {
  border: solid 1px transparent;
  outline-color: rgb(36, 233, 233);
  outline-style: solid;
  outline-width: 0.5px; /* Définir l'épaisseur de la bordure */
  outline-offset: 0;
}

/* Bouton de soumission avec un style de fond, des bordures arrondies, et des couleurs personnalisées */
.CherSubmit {
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fdc900;
  border-radius: 10px;
  color: #fff;
  border: 2px solid transparent;
  font-weight: 400;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
}

/* Style du bouton de soumission au survol : changement de couleur et de bordure */
.CherSubmit:hover {
  color: #000;
  background: none;
  border: 2px solid #275491;
}
@media (max-width: 961px) {
  .style-cherche,.style-Domaine,.style-gouv{
    width: 20%
  }
}
@media (max-width: 519px) {
  .cherche-coach{
    display: flex;
    flex-direction: column;
    margin: 100px   0;
  }
  .style-cherche,.style-Domaine,.style-gouv{
    width: 100%
  }
  /* Titre principal avec une largeur de 100%, marges, alignement centré, et style de police personnalisé */
.ChercheTitle {
  font-size: 35px;
  /* margin: 100px auto 80px auto; */
}

}