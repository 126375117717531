.containerEdit {
    width: 100%;
    background-color: #fff;
    margin: 0;
    padding-top: 50px;
    box-shadow: inset -20px 80px 80px -20px  #ccc; 
}



.ProfilEdit{
 
    background-color:transparent;
    width: 70%; /* Vous avez mentionné que vous voulez centrer dans 80% de la largeur */
    border-radius: 10px; /* Ajoutez une bordure arrondie si nécessaire */
    margin: 0 auto;
}
.ProfilEditlabel{
    font-size: 16px ;
    color: rgb(128, 128, 128)  ;
    line-height: 24px;

}
.textareaEdit {
    width: 100%; 
height: 60px;
    line-height: 2;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
        color: #495057;
            background-color:transparent;
    border: solid 1px rgb(175, 173, 173) ;
}
.bioEDIT{
    height: 150PX;
}
.textareaEdit:focus {
    border: solid 1px transparent;
    outline-color: rgb(36, 233, 233);
    outline-style: solid;
    outline-width: 0.5px; /* Définir l'épaisseur de la bordure */
    outline-offset: 0;
  }

  .domaineIntervention {
    display: flex;
flex-direction: column;
justify-content:flex-start;
align-items: start;
width: 490px;
}

.checkbox {
display: flex;
justify-content: flex-start;
align-items: center;
margin-bottom: 3px;
margin-left: 50px;
gap:5px

} 
/* .checkbox label {
 padding: 10px;
 font-size: 20px;
 line-height: 2;
 color: gray;

    }  */
    .Gouver{
        width: 100%;
        height: 40px;
        font-size: 16px;
        line-height: 2;
        color: gray;
        border: solid 1PX rgb(206, 212, 218);
        background-color: transparent;
    }
  
    
    .Gouver:focus {
        border: solid 1px transparent;
        outline-color: rgb(36, 233, 233);
        outline-style: solid;
        outline-width: 0.5px; /* Définir l'épaisseur de la bordure */
        outline-offset: 0;
      }
    .updateimage{

display: flex;
justify-content: center;
align-items: center;
gap:30px


    }

 
    .Update {
        display: flex;
        justify-content: center;
        align-items: center;
margin:40px 40%;      }
      
    
.modifier{
    padding:0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
        background-color: #fdc900;
        border-radius: 10px;
        color: #fff;
    border: 2px solid transparent;
    display: inline-block;
    font-weight: 400;
    text-align: center;

    cursor: pointer;
}
.modifier:hover{
    background-color: #fff;
    color: #000;
border: 2px solid blue;
   
}

.ProfilAnnuler{
    padding: 5px;
    font-size: 16px;
   font-weight: 400;
    color: #000;
    cursor: pointer;

}
.ProfilAnnuler:hover{
  
    color: #fdc900;;
}
