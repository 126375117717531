/* BarheaderAdmin.css */

.barheaderAdmin {
  width: 100%;
  background-color: #275491;

    margin: 0;
    padding: 0;
  }
  
  .barheaderContainerA {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center; 
    color: #e3d80a;
    padding-right: 100px;
    gap: 5PX;
  }
 
  
  .iconCompte {
    font-size: 16px; /* Taille de l'icône */
    margin-right: 10px; /* Espacement à droite de l'icône */
    font-weight: 300;

   
  }
  
  .barheadericon {
    margin: 0;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    color: #e3d80a;
    font-size: 16px; 
    font-weight: 300;
  }
  