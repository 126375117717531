
.ViewCoach {
  width: 100%;
  margin: 0;
  padding: 0;
}

.viewCoachContainer {
  width: 420px;
  margin: 20px auto 80px auto;
}

.CoachContainer {
  width:100%;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  border: solid 1px #ddd;
}

.TitreView {
  width:100%;
  display: block;
  margin: 0 auto;
  font-family: "Bell MT";
  color: rgb(111, 30, 81);
  font-size: 36px;
  font-weight: 500;
}
.LabelViewCoach{
  font-family: "Bell MT";
    font-size: 14px;
    font-weight: bold;
    font-weight: 700;
    color: rgb(51, 51, 51);

}
.styletexteCoach {
  display: block;
  text-align: start;
  font-family:  Helvetica,Arial, sans-serif;
  font-size: 14px;
  padding: 5px;
  font-weight: 400;
  color: rgb(1, 1, 43)
  ;
}
.styletexteCoach:hover{
  text-decoration: underline;
}
.border-bottom {
  border-bottom: solid 1px #ddd;
  width: 100%;
  padding: 5px;
}



.button-retour {
    color: #fff;
    background-color: #337ab7;
    display: block;
    padding: 6px 12px;
margin: 20px auto; 
   font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    border: solid 1px transparent;
    border-radius: 4px;
}
