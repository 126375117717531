.reset-pasword{
  width: 100%;
  margin: 0;
  padding: 0;
}
.reset-password-container {
    max-width: 50%;
    margin:  auto;
  
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .reset-password-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  .reset{
    color: gray;
    font-weight: 300;
  }
  .form-group-label {
    width: 100%;
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #ccc;
    font-size: 14px;
  }
  .form-group-input:focus {
    border: solid 1px transparent;
    outline-color: rgb(36, 233, 233);
    outline-style: solid;
    outline-width: 0.5px; /* Définir l'épaisseur de la bordure */
    outline-offset: 0;
    color: gray;

  }
  .reset-button {
    width: 20%;
    margin: 0 auto;
    padding: 10px;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .reset-button:hover {
    background-color: #0056b3;
  }
  
  .error {
    color: red;
  }
  
  .success {
    color: green;
  }
  