.ConsultEmail{
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Rubik", arial, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
    
}
.ConsultEmailContainer{
    width: 80%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    gap: 5px;
}
.AccueilData{
    /* width: 650px; */

    margin:auto;
     margin-top:10px; 
    margin-bottom:10px; 
    background-color:#0D345A;
    font-weight:bold; 
    padding:10px 100px;
     text-align:center; 
     color: #e3d80a;
     cursor: pointer;
     line-height: 32px;
     font-weight: 500;
     text-decoration-style:solid ;
     font-size: 20px;


}
.EmailingC{

        text-align:center;
        font-size:  28px;
         color: #000 ;
         font-weight:300;
         margin-bottom: 5px;
        
}
.SendEmail{
    background-color: rgb(240, 240, 240);
padding: 1px 6px;
border: solid 1px #000;
}