.biblioAccueil {
  width: 100%;

  margin: 0;
  padding: 0;
}

.ContainerBiblio {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  background: #fff;
  font-weight: 300;
  font-size: 16px;
  line-height: 2;
  color: gray;
}

.titreBiblio {
  width: 80%;
  text-align: center;
  font-size: 45px;
  color: rgb(253, 201, 0);
  margin: 50px auto;
  font-weight: 300;
}

.site-section {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto 50px auto;
  gap: 30px;
}

.flip-container {
  perspective: 1000px;
}

.flipper {
  transition: transform 1s;
  transform-style: preserve-3d;
  border: transparent;
}

.flipper.flipped {
  transform: rotateY(180deg);
  box-shadow: 0 0 70px -10px rgba(0, 0, 0, 0.4);
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100vh;
  backface-visibility: hidden;
}

.textCard {
  width: 100%;
  height: 418px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}

.back {
  transform: rotateY(180deg);
}

.author {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.author-img {
  border: none;
  border-radius: 0 30%;
  background-color: transparent;
  display: block;
}

.image-containerBiblio {
  position: relative;
  display: inline-block;
}

.image-containerBiblio::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.1;
  z-index: 1;
}

.IMAGE-biblio {
  width: 100%;
  height: 450px;
  display: block;
  z-index: 0; 
} 

.name {
  margin: 10px;
}

.BiblioAut {
  color: rgb(39, 84, 145);
  font-size: 16px;
}

.BiblioAnne {
  color: #e3d80a;
  font-size: 12px;
  cursor: pointer;
}

.BiblioAnne:hover {
  color: rgb(253, 201, 0);
}

.Bibliodesc {
  color: gray;
  font-style: italic !important;
  margin: 0;
  text-align: center;
  font-size: 11px !important;
  line-height: 2;
}

/* .custom-dialog {
  width: 600px;
  height: 400px;
  margin: auto;
} */

@media (max-width: 1092px) {
  .site-section {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

  }
}

@media (max-width: 814px) {
  .site-section {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);

  }
}
