@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
.TitrePub{
    width: 70%;
    font-size: 3.75rem; 
    color: white;
    margin: 0 auto;
    line-height: 1.2;
    animation: fadeIn 1s ease-in-out;
    font-weight: 300;

  }
  
  /* Autres styles existants */
  .ImagePlatforme {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .atelierPub{
    width: 100%;
  margin: 0;
  padding: 0;
  
  }
  .atelierPub-container {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: center;
      padding: 20px;
      margin: 0 auto;
      gap:30px ;
    }
    
    .cardPub {
      background-color: #fff;
      border: 1px solid #ddd;
      box-shadow:0 5px 30px -10px rgba(0, 0, 0, 0.2);
      overflow: hidden;
      width: 500px;
      height: 400px;
      text-align: left;
    }
    
    .cardPub-image {
      width: 100%;
      height: 75%;
      object-fit: cover;
    }
    
    .cardPub-content {
      padding: 16px;
    }
    
    .cardPub-title {
      font-size: 20px;
      margin: 0;
      color: #000;
      cursor: pointer;
      font-weight: 300;
  
  
    }
    .cardPub-title:hover {
      color: #043F74;
  }


/*dialogue*/
.BubContai{
  padding:40px ;
}
.Bub-titre{
  color: rgb(227, 216, 10);
  cursor: pointer;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 300;

}


.Bub-inf {
  width: 100%;
  display: flex;
  align-items: center;
justify-content: left; 

margin-top: 5px;
}

.Bub-Descrip{
  font-size: 16px;
  color: gray;
  font-weight: 400;
}

.Bub-Descrip a {
  color: #e3d80a; 
  text-decoration: none;
}

.Bub-Descrip a:hover {
  color: #fdc900; 
}

.info-item{
  padding: 2px;
  font-size: 14px;
  margin: 2px;
  border: none;
border-radius: 5%;
  color: #fff;

}

.partageBub{
  display: flex;
  justify-content: flex-start;
margin-top: 20px;
  gap: 10px;

  
}

