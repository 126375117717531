.addAtelier {
    width: 100%;
    margin: 0;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
}

.addAtelierContainer {
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
}

.addAtelierContainer  {
    display: flex;
    flex-direction: column;
}



.addAtelierContainer input[type="number"]{
    background-color: transparent;

    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 20%;
}
.imputAtelier{
    background-color: transparent;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}
.imputAtelier:focus,.addAtelierContainer input[type="number"]:focus {
    border: solid 1px transparent;
    outline-color: rgb(36, 233, 233);
    outline-style: solid;
    outline-width: 0.5px; /* Définir l'épaisseur de la bordure */
    outline-offset: 0;
  }
.btnAddAtelier {
    width: 15%;
    display: block;
    padding: 0.375rem 0.75rem; 
    background-color: #FDC900;
    color: white;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;    margin-bottom: 200px;

  }
  
  .btnAddAtelier:hover {
    color: #000;
    background: none;
    border: 2px solid #275491;
  }




.ImagePlatforme {
    position: relative;
    text-align: center;
    height: 300px;
    background-size: cover;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.LabelAtelier{
    font-family: "Rubik", arial, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
}

/* .ImagePlatforme h2 {
    font-size: 30px;
} */

/* .ImagePlatforme svg {
    font-size: 35px;
    font-weight: 400;
} */
