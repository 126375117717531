.ConsultDomaine{
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Rubik", arial, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
    
}
.ConsultDomaineContainer{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    gap: 20px;
}
.AccueilDomaine{
    width: 80%;
    height: 50px;
    display: block;
    margin: 10px auto;
    background-color: #0d345a;
    font-weight: bold;
    text-align: center;
    color: #e3d80a;
    cursor: pointer;
    line-height: 32px;
    text-decoration: none;
    font-size: 16px;


}
.DateDomaine{
    width: 22%;
    border: 1px solid #ccc; 
    padding: 8px;        font-size: 12px;
        color: gray;
    
    }
      .HeaderDomaine {
    
        border: 1px solid #ccc; 
        padding: 8px;        font-size: 10px;
        font-weight: bold;
        color: rgb(128, 128, 128);
      }