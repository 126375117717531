@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
.View{
    width: 100%;
    margin: 0;
    padding:0 ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.viewContainer{
    width: 30%;
    height: 100%;
    margin: 20px auto 50px auto;
display: flex;
align-items: start;
flex-direction: column;
border: solid 1px rgb(194, 192, 192);
border-radius: 2%;
}
.labelViewArt{

    font-family: "Titillium"    ;
    font-size: 14px;
    color: black;
    font-weight: 700;
    line-height: 20px;
    padding-bottom: 5px;
}
.styletexteART{
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
    font-size: 16px;
    color: rgb(51, 51, 51);
    font-weight: 400;

    

}
.AnnuleView{
    width: 14%;
    display: block;
    margin: "0 auto";
    background-color: #337ab7;
    padding: 6px 12px;
    color: #fff;
 
    border: solid 1px transparent;
    border-radius: 4px;
}
.AnnuleView:hover{
    background-color: #2e6da4;
}