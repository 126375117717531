.ConsultBD{
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Rubik", arial, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
    
}
.ConsultBDContainer{
    width: 80%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px auto 100px auto;
    gap: 50px;
}
.AccueilBD{
    width: 50%;
    height: 50px;
    display: block;
    margin: 40px auto;
    background-color: #0d345a;
    font-weight: bold;
    /* padding: 10px 100px; */
    text-align: center;
    color: #e3d80a;
    cursor: pointer;
    line-height: 32px;
    text-decoration: none;
    font-size: 16px;

}
.AccueilBD:hover {
    color: #fdc900;
  }
  .Data{
    border: 1px solid #ccc; 
    padding: 8px;
    width: 22%;
        font-size: 12px;
        color: gray;
    
    }
      .TableHeaderDate {
    
        border: 1px solid #ccc; 
        padding: 8px;      
          font-size: 10px;
        font-weight: bold;
        color: rgb(128, 128, 128);
      }