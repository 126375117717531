.PartenaireAjouter{
    width: 100%;
    margin: 0;
    padding: 0;
}
.ParteAjouterContainer{
    width: 80%;
    height: 100vh;
    margin: 20px auto;

}
.labelAddSlider {
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
}
.inputAddSlider {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    font-size: 14px;
    color: #535252;
    cursor: pointer;   
    padding: 0.375rem 0.75rem;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.inputAddSlider:focus {
    border: solid 1px transparent;
    outline-color: rgb(36, 233, 233);
    outline-style: solid;
    outline-offset: 0;
}
.ButtonAddSlider{
    display: block;
    padding: 0.375rem 0.75rem; 
    background-color: #FDC900;
    color: white;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .ButtonAddSlider:hover {
    color: #000;
    background: none;
    border: 2px solid #275491;
  }
