.containerr{
    width: 100%;
    height: 100vh;
    background-color: #fff;
    margin: 0;
    padding-top: 50px;
    box-shadow: inset -20px 80px 80px -20px  #ccc; 
}
.Profil{
    width: 90%; 
    border-radius: 10px; 
    margin: 0 auto;
}

.LabelProfil{
    font-size: 16px ;
    color: rgb(128, 128, 128)    ;
}
.inputStyle {
width: 100%;
background-color:transparent !important;
padding: 0.375rem 0.75rem;
font-size: 1rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da
}

.inputStyle:focus {
    border: solid 1px transparent;
    outline-color: rgb(36, 233, 233);
    outline-style: solid;
    outline-width: 0.5px; /* Définir l'épaisseur de la bordure */
    outline-offset: 0;
  }
.Update-Pasword{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 50px;
}
.Setting{
    padding:0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
        background-color: #fdc900;
        border-radius: 10px;
        color: #fff;
    border: 2px solid transparent;
    display: inline-block;
    font-weight: 400;
    text-align: center;

    cursor: pointer;
}
.Setting:hover{
    
        background-color: #fff;
        color: #000;
    border: 2px solid blue;
   
}

.ProfilAnnuler{
    padding: 5px;
    font-size: 16px;
   font-weight: 400;
    color: #000;
    cursor: pointer;

}

.ProfilAnnuler:hover{
    
    color: #fdc900;


}