.ConsultNewsLetter{
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Rubik", arial, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
    
}
.ConsultNewsLetterContainer{
    width: 60%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px  auto 200px auto;
    gap: 50px;
}
.EmailingNewsLetter{
   width: 50%;
    display: block;
    background-color: #0d345a;
    font-weight: bold;
    padding: 10px 0px;
    text-align: center;
    color: #e3d80a;
    cursor: pointer;
    line-height: 2;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    margin-top: 40px;


}
.DateNews{
    width: 22%;
    border: 1px solid #ccc; 
    padding: 8px;        font-size: 12px;
        color: gray;
    
    }
      .TableHeaderNews {
    
        border: 1px solid #ccc; 
        padding: 8px;        font-size: 10px;
        font-weight: bold;
        color: rgb(128, 128, 128);
      }
      