.edit-Evenement {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FormContainerEvenement {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LabelEditVedio {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgb(51, 51, 51);
    font-weight: 700;
}

.inputVideoEdit {
    width: 100%;
    height: 43px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    font-size: 14px;
    color: #535252;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
}
.ItemVedioEdit{
    display: flex;
    flex-direction: column;
}
.tox{
    width: 100%;
}
.inputVideoEdit:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.form-group-Video {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}
.imageEdit-video{
    display: block;
    margin: 20px auto;
    width: 150px;
}
.Bouton-Edit-Article {
    display: flex;
    justify-content: center;
    gap: 20px; 
}
.btn-EditVideo, .btn-NoEditVideo {
    cursor: pointer;
}
.btn-EditVideo{
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 4px;
}
.btn-NoEditVideo {

    color: #333;
    background-color: #fff;
    border-color: #ccc;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    background-image: none;
    border-radius: 4px;
}

.btn-NoEditVideo:hover{
    background-color: #ccc;
}
.btn-EditVideo:hover {
    background-color: #2e6da4;

    
  }
  .btn-EditVideo:focus {
    border:solid 2px  #000;

    
  }