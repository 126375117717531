/* slide.css */
.carousel-item {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Ajustez la hauteur en fonction de vos besoins */
}

.carousel-img {
  width: 100%; /* Ensure the image takes up 100% of the container width */
}

.slide-title {
  width: 100%;
  position: absolute;
  display: block;
  
  font-size: 60px; /* Font size adjusts with viewport width */
  font-weight: 800;
  top: -300px;
  left: -250px;

  overflow: visible;
  z-index: 1;
  color: rgb(39, 84, 145);
  animation: slideFromLeft 1s ease-in-out forwards,
    centerText 1s ease-in-out forwards;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);

     font-family: "Anton" , sans-serif; 
  
     overflow: visible; transform-origin: 50% 50%; z-index: 1; 
}



@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes centerText {
  0% {
    opacity: 0;
    transform: translateY(-50%) translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(-50%) translateX(0%); /* Adjust horizontal position */
  }
}
/*  */
/* Optional: Additional media queries for fine-tuning */
@media (max-width: 768px) {
  .slide-title {
    font-size: 8vw; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .slide-title {
    font-size: 10vw; /* Further adjustment for very small screens */
  }
}
