.ConsultContact{
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Rubik", arial, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
    
}
.ConsultContactContainer{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    gap: 50px;
}
.AccueilContact{
    margin:auto;
     margin-top:50px; 
    margin-bottom:50px; 
    background-color:#0D345A;
    /* font-weight:bold;  */
    padding:10px 200px;
     text-align:center; 
     color: #e3d80a;
     cursor: pointer;
     line-height: 32px;
     /* font-weight: 500; */
     text-decoration-style:solid ;
     font-size: 20px;


}

.DataContact{
    /* width: 22%; */
    border: 1px solid #ccc; 
    padding: 8px;        font-size: 12px;
        color: gray;
    
    }
      .TableHeaderContact {
    
        border: 1px solid #ccc; 
        padding: 8px;        font-size: 10px;
        font-weight: bold;
        color: rgb(128, 128, 128);
      }
      
    
.IconContact{


    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

