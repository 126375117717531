.ConsultBiblio{
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Rubik", arial, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
    
}
.ConsultBiblioContainer{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    gap: 20px;
}
.AccueilBiblio{
    width: 50%;
    height: 50px;
    display: block;
    margin: 40px auto;
    background-color: #0d345a;
    font-weight: bold;
    text-align: center;
    color: #e3d80a;
    cursor: pointer;
    line-height: 32px;
    text-decoration: none;
    font-size: 16px;


}
.ImagSlide{
    width: 200px;
    height: 100px;
}