@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.Platforme{
  width: 100%;
padding: 0;
margin: 0;
}
.Platforme-container {
    width: 80%;
   display: flex;
   /* align-items: center;
   justify-content: center; */
   gap: 100PX;
   background: #fff;
   font-size: 18px;
   line-height: 2;
   color: gray;
   margin: 120px auto;
   font-weight: 300;
  }
     .Heading {
width: 50%;
        /* margin-bottom: 60px; */
    }
    .TitlePlateforme{
      font-size: 45px;
      margin: 0;
      padding: 0;
      color: #275491;
      font-weight: 300;
/* line-height: 1.2;   */ } 
    .TextPlatteforme{
font-size: 16px;
line-height: 32px;
color: rgb(128, 128, 128);
      text-align: justify;
    }
    .atelier-image{
      width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        margin: 0 auto;

    }
    
    .atelier-image .icon-play{

        position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.5); /* White transparent background */
  border-radius: 50%;
  padding: 20px;  /* Adjust to your liking */
  font-size: 80px;
  color: #e3d80a;
  opacity: 0.8;  ;
    }
    .AtelierInscri{
        width: 60%;
        height: 70px;
    cursor: pointer;
    display: block;
    margin:20px auto  ;
    

    background-color: #275491;
    border: none;
    color: white;
    padding: 15px 32px;
        text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    }



  @media (max-width: 1155px) {
    .Platforme-container{
      width: 90%;
    }
      .TitlePlateforme{
        font-size: 35PX;
  }

  

}
@media (max-width: 851px) {
  .Platforme-container{
    width: 95%;
  }
    .TitlePlateforme{
      font-size: 35PX;
}


}
@media (max-width:809px) {
.Platforme-container{
  width: 90%;

  display: flex;
  flex-direction: column;
}
.Heading{
  width: 100%;
}
.atelier-image{
  width: 80%;

}
}