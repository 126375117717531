
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .Platforme{
    width: 100%;
  padding: 0;
  margin: 0;
  }
  
  

  .PlatformePartageTem {
    position: relative;
    text-align: center;
    min-height: 230px;
    height: 230px;
        display: block;
    justify-content: center;
    align-items: center;
    background-size:cover;
    overflow: "hidden";
    display: "flex";
    justify-content: "center";
    align-items: "center";
    background-position: center center;
    
  }
  
  .TemTitre{
    width: 80%;
    font-size: 60px;
    color: white;
    margin: 0 auto;
    line-height: 2.5;
    animation: fadeIn 1s ease-in-out;
    font-weight: 300;
  
    font-weight: 300;
  }
  
  .PlatformePartageTem.section-hero {
  background-size: cover;
  background-position: center;
}


.temoign {
  padding: 0px;
  width: 100%;
  margin: 0 ;
  background-color: #F8F9FA;
}
.temoignages-container {
  padding: 100px;
  width: 75%;
  margin: 0px ;
  
}

.temoignage-item {
  display: flex;
  /* align-items: center; */
  padding: 20px;
  background-color: transparent;
  margin-bottom: 10px;
}

.temoignage-icon {
  margin-right: 10px;
  background-color: rgb(255, 255, 255,0.5);
  border-radius: 50%;
  color: #BBB;
  padding: 10px;
  font-size: 80px;
}

.temoignage-content {
  flex: 1;
}

.nom-TEM {
  margin: 0;
  font-size: 20px;
  font-weight: 300;
  color: #000;
  line-height: 24px;
}

.nom-TEXTE {
  margin: 5px 0;
  font-size: 16px;
  font-weight: 300;
  color: rgb(128, 128, 128)  ;
  line-height: 32px;
}

.temoignage-button {
  color: rgb(227, 216, 10) ;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;

}

.temoignage-button:hover {
  color: rgb(253, 201, 0)  ;
}
.partagerTEM{
  margin-top: 40px;
display: flex;  
gap: 20px;}