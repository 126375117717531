

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.ImagePlatformeVedio {
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 260px;
  background-size: cover;
  overflow: hidden;
  background-position: center center;
}

.Vedio-Titre {
  width: 70%;
  font-size: 40px; 
  color: white;
  line-height: 1.2;
  animation: fadeIn 1s ease-in-out;
  font-weight: 300;
  text-align: center;
  margin: 0;

}
.videoCoach{
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #F8F9FA;
}
.videoCoachContainer{
    width: 80%;
    margin: 0 auto;
    animation: fadeIn 1s ease-in-out;

}
.videoTitre{
  width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;

    color: rgb(39, 84, 145);
    font-size: 32px;
    font-weight: 300 ;
    line-height: 38.4px;
    text-align: left;
}

.video-list {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns of equal width */
  gap: 20px; /* Space between grid items */
  margin-top: 80px;
  }
  
  .Imagevideo{
    width:100%;
    height:300px;
    border-radius: 0;
  }



.info-item{
  padding: 2px;
  font-size: 14px;
  margin: 2px;
  border: none;
border-radius: 5%;
  color: #fff;

}
.TitreVideo {

  color: rgb(39, 84, 145);
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
}

.ImageVideo {
  position: relative;
  width: 100%;
  height: 300px;
}

.iconVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255,0.5);
  border-radius: 50%;
  padding: 20px;
  font-size: 60px;
  color: #e3d80a;
  opacity: 0.8;  ;
}
.Partage{
  color: rgb(227, 216, 10);
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  margin: 20px;
}
.css-rnmm7m-MuiPaper-root-MuiDialog-paper{
  max-width: 800px !important;
  width: calc(60% - 64px);}
  @media (max-width: 767PX) {
    .video-list {
      grid-template-columns: repeat(1, 1fr); /* Two columns of equal width */
    
    }
  }