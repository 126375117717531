@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.Titre-Ate-Duge {
  width: 70%;
  font-size: 3.75rem; /* Equivalent à 60px si la base est 16px */
  color: white;
  margin: 0 auto;
  line-height: 1.2;
  animation: fadeIn 1s ease-in-out;
  font-weight: 300;

}

/* Autres styles existants */
.ImagePlatforme {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
}
.atelier-Degus{
  width: 100%;
margin: 0;
padding: 0;

}
.atelierD-container {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    padding: 20px;
    margin: 0 auto;
    gap:30px ;
  }
  
  .card-D {
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow:0 5px 30px -10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 500px;
    height: 400px;
    text-align: left;
  }
  
  .card-D-image {
    width: 100%;
    height: 75%;
    object-fit: cover;
  }
  .card-D-titre {
    font-size: 20px;
    margin: 2px 15px;
    color: #000;
    cursor: pointer;
    font-weight: 300;
  }
  .card-D-titre:hover{
    color: #043F74;

  }
  .card-D-content {
    padding: 16px;
  }
  
  .card-D-title {
    font-size: 20px;
    margin: 0;
    color: #000;
    cursor: pointer;
    font-weight: 300;


  }
  .card-D-title:hover {
    color: #043F74;
}