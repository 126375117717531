.AccueilNews{
    width: 50%;
    height: 50px;
    display: block;
    margin: 40px auto;
    background-color: #0d345a;
    font-weight: bold;
    /* padding: 10px 100px; */
    text-align: center;
    color: #e3d80a;
    cursor: pointer;
    line-height: 32px;
    text-decoration: none;
    font-size: 16px; 
}
.btn-send{
background-color: rgb(240, 240, 240);
padding: 1px 6px;
border: solid 1px #000;
}
.emailingNews{

    text-align:center;
    font-size:  28px;
     color: #000 ;
     font-weight:300;
    }




