
.Login {
    font-family: 'Times New Roman', Times, serif;
    background-color: #0B355C;
    position: relative;
    margin: 0;
    padding: 0;
    height: 659PX;

 }
.containe {
    background-color: #FFF;
    width: 35%;
    height: 350px;
    padding: 30px;
    position: relative;
    left: calc(50vw - 250px); /* Centrer horizontalement */
    top: calc(50vh - 200px); /* Centrer verticalement */
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* .containe form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
} */
.Loginconnection{
    font-family: "Times New Roman";
    font-size: 32px;
    color: #000;
    text-align: center;
}
.logintitle {
    display: block;
    font-family: "Times New Roman";

    color: #333;
    text-align: start;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    cursor: default;
}

.inputadmin {
    width: 100%;
    font-size: 15px;
    height: 40px;
    margin-bottom: 10px;
    border: solid 1px #ccc;
color: #ccc;

    border-color: #b2bfc7;
    padding-left: 12px;
}
.input-container {
    position: relative;
    width: 100%;

  }
  
  .password-input {
    width: 100%;
    padding-right: 40px; /* Add space for the icon */
    border: solid 1px #ccc;
    color: #ccc;

  }
  .password-input:focus,  .inputadmin:focus {
    border: solid 1px transparent;
    outline-color: rgb(36, 233, 233);
    outline-style: solid;
    outline-width: 0.5px; /* Définir l'épaisseur de la bordure */
    outline-offset: 0;
  }
  .password-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 20px;
    color: #666;
  }
  
.mdpoublie {
    color: #8894A9;
    text-align: end;
    font-size: 16px;
    font-weight: 400;
    cursor: default;
}

.clear {
    clear: both;
}

.loginAdmin {
    background-color: rgb(83, 175, 87)    ;
    color: white;
    padding: 12px 20px;
    margin: 0px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.loginAdmin:hover {
    background-color: #FFF;
    border:solid 1px rgb(83, 175, 87);
    color: rgb(83, 175, 87)
    ;
}

@media (max-width: 767px) {
    .containe {
        width: 300px; /* ou une autre valeur appropriée */
        left: 10vw; /* Ajustez la position horizontale selon vos besoins */
        top: 5vh; /* Ajustez la position verticale selon vos besoins */
    }
}
