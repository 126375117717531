.addPub {
    width: 100%;
    margin: 0;
    padding: 20px;

    background-color: #fff;
}

.addPubContainer {
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;

}


.buttonAccueil {
    width: 50%;
    height: 50px;
    display: block;
    margin: 40px auto;
    background-color: #0d345a;
    font-weight: bold;
    padding: 10px 100px;
    text-align: center;
    color: #e3d80a;
    cursor: pointer;
    line-height: 32px;
    text-decoration: none;
    font-size: 16px;
  }
  
.LabelAddPub {
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
}
.inputAddPubl {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    font-size: 14px;
    color: #535252;
    cursor: pointer;   
    padding: 0.375rem 0.75rem;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.editorPub{
    height: 200px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    font-size: 14px;
    color: #535252;
    cursor: pointer;   
    padding: 0.375rem 0.75rem;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.inputAddPubl:focus,.editorPub:focus {
    border: solid 1px transparent;
    outline-color: rgb(36, 233, 233);
    outline-style: solid;
    outline-offset: 0;
}




.button-Container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Espace entre les boutons */
    margin-bottom: 50px;
    margin-top: 50px;
  }
  
  .btnAddPub, .btnAnnulerAdd{
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 4px;

  }
.btnAddPub{
    background-color: #337ab7;
    border-color: #2e6da4;
    color: white;  
}
.btnAnnulerAdd {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
  }
.btnAnnulerAdd:hover{
    border:solid 2px #337ab7;
}
.btnAddPub:hover {
    background-color: #2e6da4 ;

    
  }
  .btnAddPub:focus {
    border:solid 2px  #000;

    
  }


