.PlatformePartageTem {
    position: relative;
    text-align: center;
    min-height: 230px;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
}

.TemTitre {
    width: 80%;
    font-size: 60px;
    color: white;
    margin: 0 auto;
    line-height: 2.5;
    animation: fadeIn 1s ease-in-out;
    font-weight: 300;
}
.FAQ{
    padding: 0;
    width: 100%;
    margin: 0 ; 
    background-color: #F8F9FA;
  
}
.faq-container {
    padding: 100px;
    width: 75%;
    margin: 0 ;
    display: flex;
}

.faq-item {
    display: flex;
    flex-direction: column;
    padding: 0px;
}
.FAQIcon {
    margin-right: 10px;
    background-color: rgb(255, 255, 255,0.5);
    border-radius: 50%;
    color: #000;
padding: 10px;
  }
.faq-question {
    margin: 0;
  font-size: 20px;
  font-weight: 300;
  color: #000;
  line-height: 24px;
}

.faq-answer {
    margin: 5px 0;
    font-size: 16px;
    font-weight: 300;
    color: rgb(128, 128, 128)  ;
    line-height: 32px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
