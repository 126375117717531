
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .ImagePlatformeEvnAdd {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    background-size: cover;
    overflow: hidden;

    background-position: center center;
  }
  
  .AddEvntTitre {
    width: 30%;
    font-size: 60px; 
    color: white;
    line-height: 1.2;
    animation: fadeIn 1s ease-in-out;
    font-weight: 300;
    text-align: center;
    margin: 0;
  }
  .AjouterEVNT{
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #F8F9FA;

  }
.ContainerEvnt {
    width: 80%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 0px auto;
    padding-top: 80px;

}

.left-Evnt {
    width: 33%;


}
.Evnt-Item{
  background-color: #fff;
  padding: 20px;

  margin-bottom:20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.right-Evnt {
    width: 67%;
    padding: 80px 20px;
    background-color: #fff;
}

.EvntPartager{
    text-align: center;
    color: #275491;
    font-size: 20px;
    font-weight: 300;
    line-height: 40px;
    text-align: center;
}

.left-Evnt-img {
    width: 65%;
    height: 100px;
    border: solid 1px gray;
    border-radius: 5px;
    display: block;
    margin: 0 20px;
}

.EvntTitre{
    font-size: 20px;
    margin-top: 10px;
    color: rgb(227, 216, 10);
    font-weight: 300;
    line-height: 24px;
    cursor: pointer;
}

.EvntDate {
    font-size: 16px;
margin-bottom: 0;
    font-weight: 300;
    line-height: 32px;
    color: rgb(128, 128, 128);
}

.formulaireEvnt {
    margin-bottom: 0.5rem;
    font-size: 24px;
    font-weight: 300;
    color: #000;
}

.LabelEvnt{
  font-size: 16px;
  color: rgb(128, 128, 128)  ;
  margin: 10px 0;
}
.InputEvnt {

  width: 100%;
  height: 40px;
  border: solid 0.5px rgb(206,212,218);

}
.AddEvt{
  width: 100%;
  height: 400px;
  border: solid 0.5px rgb(206,212,218);

}
 .tox{
  width: 100%;
  height: 40px;
}
.InputEvnt:focus,.AddEvt:focus
 {
  border: solid 0.5px transparent;

  outline-color: rgb(36, 233, 233) ;
  outline-style: solid ;

  outline-width: 0.5px; 
  outline-offset: 0;
}

.AddEvnt{
    display: block;
    margin: 0 auto;
    background-color: #fdc900;
        color: #fff;
        text-align: center;
    border-radius: 10px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    border: 2px solid transparent;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    margin-top: 30px;
    }
  
  
.Evnt-image {
  width: 300px;
  height:100px ;
  display: block;
margin: 30px auto;
}
.Evnt-titre {
  color: #e3d80a;
cursor: pointer;
line-height: 1.2;
font-size: 2rem;
text-align: justify;
font-weight: 300;
margin-bottom: 20px;


}
.Evnt-titre:hover {
  color: #fdc900;
}


.Evnt-descri{
  font-size: 16px;
  color: gray;
}

.Evnt-descri a {
  color: #e3d80a; 
  text-decoration: none;
}

.Evnt-descri a:hover {
  color: #fdc900; 
}

.Evnt-info {
  width: 100%;
  display: flex;
  align-items: center;
justify-content: left; 
 background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
padding: 20px ;
box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 30px -10px;
gap: 30px;

}




.info-icon {
  color: rgb(156, 155, 155);
  margin-right: 5px; 
}

.info-lieu,.info-date {
  font-family: "Rubik", arial, sans-serif;
  font-size: 15px;
  line-height: 1;
  text-align: justify;
  font-weight: 300;
  color: rgb(179, 179, 179);
  margin: 0;
}






.info-item{
  padding: 2px;
  font-size: 14px;
  margin: 2px;
  border: none;
border-radius: 5%;
  color: #fff;

}
.partagerEVNT{
  margin-top: 40px;
display: flex;  
gap: 20px;}

.error-message{
  color:rgb(238, 66, 66) ;
  font-size: 13px;
}
@media (max-width: 991PX) {
  .left-Evnt {
    width: 50%;
 
}

.right-Evnt {
  width: 50%;

}
}
@media (max-width: 767PX) {
  .ContainerEvnt {
    display: flex;
   flex-direction: column-reverse;

}
  .left-Evnt {
    width: 100%;
 
}

.right-Evnt {
  width: 100%;

}
}