@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.ImagePlatforme {
  width: 100%;
  padding: 0;
  margin: 0;
}

.FORMECOACH {
  width: 80%;
  margin: 0 auto;
  animation: fadeIn 1s ease-in-out;

}

.coach_Titre {
  width: 100%;
  font-size: 40px;
  color: white;
  line-height: 1.2;
  animation: fadeIn 1s ease-in-out;
  font-weight: 300;
}

.fieldsCOACH {
  width: 100%;
  
  background-color: #fefefe;
  padding: 10px 20px;
  display: flex;
  position: relative;
}

.formulairecoach {
  width: 100%;
  display: flex;
  color: #999;
  padding: 0;
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
justify-content: space-between;
}

.remplirFormulaire {
  padding: 25px 70px;
  background-color: #275491;
  color: #fff;
  border: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
  font-size: 16px;
  margin: 0px auto;
}

.remplir-Article {
  width: 30%;
  height: 70px;
  margin: 0 auto;
  background-color: #275491;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  /* display: inline-block; */
  font-size: 16px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center center;

}

.Add-Article {
  width: 80%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
  animation: fadeIn 1s ease-in-out;

}

.ARTICLEright {
  width: 50%;
  display: block ;
  margin: auto;
}

.ParcourArticle {
  background: #fff;
  font-weight: 300;
  font-size: 16px;
  line-height: 8px;
  color: rgb(39, 84, 145) ;
}

.ContainerArticle {
  position: relative;
  display: inline-block;
  width: 100%;
  /* height: 100vh; */
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.image-Article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.icon-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  padding: 20px;
  z-index: 1;
  color: yellow;
  font-size: 50px;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .coach_Titre {
    font-size: 35px;
  }

  .formulairecoach {
    font-size: 1.8rem;
  }

  .remplirFormulaire {
    padding: 20px 60px;
    font-size: 14px;
  }

  .ContainerArticle {

    height: 100vh;
  }
}

@media (max-width: 950px) {
  .formulairecoach {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .coach_Titre {
    font-size: 30px;
  }

  .remplirFormulaire {
    padding: 15px 50px;
    font-size: 12px;
  }

  .ContainerArticle {

    height: 100vh;
  }


}

@media (max-width: 767px) {
  .Add-Article {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    align-items: center;
  }

  .remplir-Article {
    width: 80%;
  }

  .ARTICLEright {
    width: 100%;
  }

  .coach_Titre {
    font-size: 25px;
  }

  .formulairecoach {
    font-size: 1.5rem;
  }

  .remplirFormulaire {
    padding: 10px 40px;
    font-size: 12px;
  }

  .ParcourArticle {
    font-size: 14px;
  }
  .ContainerArticle {

    height: 100vh;
  }
}
