.ConsultList{
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Rubik", arial, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
    
}
.ListContainer{
    width: 80%;

   margin: 10px   auto;
}
.LISTbuttonContainer{
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 20px;
}
.btnEmailingProf,.btnAccueilPROF{

    width: 50%;
    height: 50px;
        background-color:#0D345A;
        /* padding:10px 100px; */
         text-align:center; 
         cursor: pointer;
         line-height: 32px;
         text-decoration-style:solid ;
         font-size: 16px;
         font-weight: bold;
    margin: 0px auto;
     color: #e3d80a;
 


}
.DataList{
    border: 1px solid #ccc; /* Cell borders */
    padding: 8px;        font-size: 12px;
        color: gray;
    
    }
      .headerList {
    
        border: 1px solid #ccc; /* Cell borders */
        padding: 8px;        font-size: 10px;
        font-weight: bold;
        color: rgb(128, 128, 128);
      }
      