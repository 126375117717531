.ConsultArtVisib {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .ConsultArtVisibContainer {
    width: 80%;
    margin: 0 auto;
  }
  .TableArtvisib {
    width: 100%;
    text-align: center;
    margin: 40px 0 200px 0;
    border-collapse: collapse; 
    border: 1px solid #ccc; 
}
  .buttonAccueilArct {
    width: 50%;
    height: 50px;
    display: block;
    margin: 40px auto;
    background-color: #0d345a;
    font-weight: bold;
    /* padding: 10px 100px; */
    text-align: center;
    color: #e3d80a;
    cursor: pointer;
    line-height: 32px;
    text-decoration: none;
    font-size: 16px;
  }
  
  .AccueilArtVisib:hover {
    color: #fdc900;
  }
  
  .TableArtInvisib {
    width: 100%;
    text-align: center;
    margin: 40px 0 200px 0;
    border-collapse: collapse; 
    border: 1px solid #ccc; 
}

  .TableHeader {
    border: 1px solid #ccc; 
    padding: 8px;
    padding: 8px;
    font-size: 12px;
    font-weight: bold;
    color: rgb(128, 128, 128);
  }
  
  .TableData {
    border: 1px solid #ccc; 
    padding: 8px;
    padding: 8px;
    color: rgb(128, 128, 128);
    font-size: 12px;


  }
  
  .TableDataimg {
    width: 200px;
    padding: 5px;
  }
  
  .IconData {
    margin:  0 20px; /* Space between icons */

    font-size: 20px;
    color: black;
  }
  