.DomaineEdit {
    width: 100%;
    margin: 0;
    padding: 0;
}

.DomaineEditContainer {
    width: 35%;
    margin: 50px auto;

 
}
.LabelEditD{
    width: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color:rgb(51, 51, 51);
    font-weight: 700;
    margin-bottom: 5px;
}
.textAreaDomaine {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    font-size: 14px;
    color: #535252;
    cursor: pointer;   
    padding: 0.375rem 0.75rem;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    border-radius: 4px;
}
.textAreaDomaine:focus {
    border: solid 1px transparent;
    outline-color: rgb(36, 233, 233);
    outline-style: solid;
    outline-offset: 0;
}


.EditDomaine {
    display: flex; 
    justify-content: center; 
      margin: 10px auto;
      gap: 10px;
    }
    .submitDomaine{
        color: #fff;
        background-color: #337ab7;
        border-color: #2e6da4;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: center;
        border: 1px solid transparent;
        border-radius: 4px;
        cursor: pointer;
    
    }
    .AnnuleDomaine{
        color: #333;
        background-color: #fff;
        border-color: #ccc;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
    
    }
    .AnnuleDomaine:hover{
        background-color: #ccc;
    }
    .submitDomaine:hover {
        background-color: #2e6da4;
    
        
      }
      .submitDomaine:focus {
        border:solid 2px  #000;
    
        
      }