
.FormBiblio{
    width: 100%;
    margin: 0;
    padding: 0;
}
.FormContainerBiblio {
    width: 40%;
    margin: 20px auto;
    
}
.labelBiblio{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color:rgb(51, 51, 51);
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 10px;

}
.textAreaBiblio{
    height: 200px;
}
.inputBiblio{
    height: 34px;

}
.inputBiblio,.textAreaBiblio{
    width: 100%;
    padding: 6px 12px;
    font-family:"Helvetica Neue", Helvetica, Arial, sans-serif
;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.inputBiblio:focus,.textAreaBiblio:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.Bouton-Edit-Article {
 
    display: flex; 
margin: 40px auto;
gap: 20px;
} 
.imageEditBiblio{
    width: 100px;
    margin: 0 auto;
} 


.buttonsContainer {
    display: flex;
    justify-content: center;
    gap: 20px; /* Espace entre les boutons */
    margin-bottom: 50px;
  }
  
  .btnEditBiblio, .btnAnnuleBiblio{
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 4px;

  }
  
  .btnEditBiblio {
    background-color: #337ab7;
    border-color: #2e6da4;
    color: white;
    
  }
  
  .btnAnnuleBiblio {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
  }
.btnAnnuleBiblio:hover{
    background-color: #ccc;
}
.btnEditBiblio:hover {
    background-color: #2e6da4;

    
  }
  .btnEditEvnt:focus {
    border:solid 2px  #000;

    
  }