.CoachEdit {
    width: 100%;
    margin: 0;
    padding: 0;
}
.CoachEditContainer {
    width: 420px;
    margin: 0 auto; 
    display: flex;
    flex-direction: column;
}
.CoachContainer {  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%; 
}
.LabelCoachEdit {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgb(51, 51, 51);
    font-weight: 700;
    margin-bottom: 5px;
}
.textCochEdit {
    width: 100%;
    height: 55px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    font-size: 14px;
    color: #535252;
    cursor: pointer;   
    padding: 0.375rem 0.75rem;
    border-radius: 5px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.textCochEdit:focus {
    border: solid 1px transparent;
    outline-color: rgb(36, 233, 233);
    outline-style: solid;
    outline-offset: 0;
    box-shadow: 0 1px 5px rgb(36, 233, 233);
}
.GouverEdit {
    width: 100%;
    height: 40px;
    font-size: 16px;
    line-height: 2;
    color: gray;
    border: solid 1px rgb(206, 212, 218);
    background-color: transparent;
}
.editdomaine{
    font-size: 14px;
    font-weight: 400;
    line-height: 20;
    color: rgb(51, 51, 51)
    ;
}
.GouverEdit:focus {
    border: solid 1px transparent;
    outline-color: rgb(36, 233, 233);
    outline-style: solid;
    outline-width: 0.5px;
    outline-offset: 0;
    box-shadow: 0 1px 5px rgb(36, 233, 233);
}
.EDITCOACH {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}
.ButtonEditCoach {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 4px;
}
.ButtonCoachAnnuler {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    border-radius: 4px;
}
