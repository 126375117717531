.WorkshopPage {
    width: 100%;
    margin: 0;
    padding: 20px;
  
    background-color: #f9f9f9;
}

.WorkshopPageContainer {
    width: 30%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.ListpubAtelier{
    width: 80%;
    margin: 0 auto;
}
.AddPub ,.ListPro,.ListCoach{
    width: 100%;
    height: 50px;
    /* padding: 10px 20px; */
    margin: 10px 0;
    background-color: #0C4270;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}



.HeaderPub{
    border: 1px solid #ccc; 
    padding: 8px;
    padding: 8px;
    font-size: 12px;
    font-weight: bold;
    color: rgb(128, 128, 128);
}
.TableDataPub {
    border: 1px solid #ccc; 
    padding: 8px;
    padding: 8px;
    color: rgb(128, 128, 128);
    font-size: 12px;


  }