.edit-Tem {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}


.FormTemContainer {
    width: 40%;

    margin: 20px auto;

}


.labelEditTem{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color:rgb(51, 51, 51);
    font-weight: 700;
}
.inputEditTem {
    width: 100%;
    height: 43px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    font-size: 14px;
    color: #535252;
    cursor: pointer;   
    padding: 0.375rem 0.75rem;
  }
  
  .inputEditTem:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  }
  










.Bouton-Edit-Tem  {
    display: flex;
    justify-content: center;
    gap: 20px; /* Espace entre les boutons */
    margin-top: 20px;
  }
  
  .btn-EditTem, .btn-AnnulerTem {
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 4px;

  }
  
  .btn-EditTem {
    background-color: #337ab7;
    border-color: #2e6da4;
    color: white;
    
  }
  
  .btn-AnnulerTem {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
  }
.btn-AnnulerTem:hover{
    background-color: #ccc;
}
.btn-EditTem:hover {
    background-color: #2e6da4;

    
  }
  .btn-EditTem:focus {
    border:solid 2px  #000;

    
  }