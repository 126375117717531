
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .platforme-contact {
    position: relative;
    text-align: center;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
  }
  
  .contact-titre {
    width: 100%;
    font-size: 3.75rem; 
    color: white;
    margin: 0 auto;
    line-height: 1.5;
    animation: fadeIn 1s ease-in-out;
    font-weight: 300;
  
  }
.contact-coach{
    width: 100%;
    margin: 0;
    padding: 0;
}
.Contact-coach-Container{
    width: 80%;
    margin: 100px auto;
    display: flex ;
    /* align-items: center; */
    justify-content: center;
    gap: 60px;
    animation: fadeIn 1s ease-in-out;

}
.left-Contact{
    width: 70%;
}
.right-Contact{
    width: 30%;

}
.form-group{
  width: 100%;
  display: flex;
  gap: 20PX;
}
.form-field{
  display: flex;
  flex-direction: column;
}
.contact-label{
font-size: 16px;
margin-bottom: 10px;
color: rgb(157,157,157);}
.contact-input{
width: 100%;
height: 40px;
border: solid 0.5px rgb(206, 212, 218);


}


.contact-textarea{
    width: 100%;
    height: 200px;
    border: solid 0.5px rgb(206, 212, 218);

}
.contact-input:focus,.contact-textarea:focus
 {
  border: solid 0.5px transparent;

  outline-color: rgb(36, 233, 233) ;
  outline-style: solid ;

  outline-width: 0.5px; 
  outline-offset: 0;
}
/**right*****/
.information-titre{
margin-bottom: 3rem;
color: #000;
font-size: 1.75rem;
line-height: 1.2;
font-weight: 300;
}
.info-contact{
    display: flex;
    align-items: center;
    color: rgb(128,128,128);
    margin-bottom: 20px;
    gap: 15px;
    cursor: pointer;
}
.social-links{
  display: flex;
  flex-direction: row;
  gap: 10PX;
}
.info-detail,.contact-social{
    color: #e3d80a;
    font-weight: 300;
    font-size: 16px;
}
.info-detail:hover,.Info-mail:hover,.contact-social:hover{
    color: #fdc900;
    font-weight: 300;
    font-size: 16px;
}
.contact-button{
    cursor: pointer;
    font-weight: 400;
    text-align: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
background-color: #fdc900;
color: #fff;
border-radius: 10px;
border: solid 1px transparent;

}
.contact-button:hover{
    color: #000 ;
    background: none ;
    border: 2px solid #275491 ;
}
@media (max-width: 792PX) {
  .Contact-coach-Container {
display: flex;  
flex-direction: column;
  }
  .left-Contact{
    width: 100%;

}
.right-Contact{
    width: 100%;

}
.form-group{
display: flex;
flex-direction: column;
}
}