.Icon {
  width: 100%;
  margin: 0;
  padding: 0;
}

.grid-containericon {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 auto;
  gap: 10px; /* Ajoute un espace entre les éléments */
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
}

.subgrid-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.ImgIcon{
  display: block;
  margin: 0 auto;
  font-size: 16px;
  margin-bottom: 10px;
}
.icontitle {
  display: block;
  margin:1rem auto !important;
 font-weight: 400;
  font-size: 20px;
  
  color: #000;
  margin: 0;
}

.icondescrip {
text-align: justify ;
  font-family: "Rubik", Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;

  line-height: 1.5;
  color: rgb(128, 128, 128)  ;
  /* text-align: start; */
}

.Affichersuit{
  color: #e3d80a;
  font-size: 16px;
  cursor: pointer;
  margin-top: 60px; 
  font-weight:300 ;
  font-family: Arial, Helvetica, sans-serif;
}


.Affichersuit:hover{
  color: rgb(253, 201, 0)  ;
}
@media (max-width: 992px) {
  .grid-containericon {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);

    gap: 40px; /* Ajoute un espace entre les éléments */
  }
}
@media (max-width: 527px) {
  .grid-containericon {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    gap: 40px; /* Ajoute un espace entre les éléments */
  }
}