@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .ImagePlatformecherche {
    position: relative;
    text-align: center;
    min-height: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    overflow: hidden;
    background-position: center center;
  }
  
  .cherchetitre {
    width: 100%;
    font-size: 60px;
    color: white;
    margin: 0 auto;
    line-height: 1.5;
    animation: fadeIn 1s ease-in-out;
    font-weight: 300;
  }
  
  .ContainerFilter {
    background-color: #f8f9fa;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Rubik", arial, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
    padding: 50px 0;
    height: 100%;
  }
  
  .episodes {
    width: 70%;
    background-color: #fff;
    padding: 20px;
  }
  
  .ContainerCoach {
    width: 100%;
    background-color: #f8f9fa;
    padding-left: 0px;
    padding-right: 0px;
  }

  .Coach {
    display: flex;
    justify-content: space-between;
    
    align-items: center;
    padding:15px;
    border-bottom: solid 1px #ccc;
  }
  
  .nomCoach {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: 300;
    color: #000;
    line-height: 29px;
  }
  
  .nomCoach:hover {
    color: gray;
  }
  
  .nomCoachLieu {
    font-size: 14px;
    font-weight: lighter;
    color: rgb(39, 84, 145);
  }
  
  .CenterText {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 20px;
    color: rgb(128, 128, 128)    ;
    background-color: #fff;
    font-weight: 300;
  }
  