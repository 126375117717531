
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .PlatformeA{
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .Image-Platforme {
    position: relative;
    text-align: center;
    min-height: 250px;
    height: 250px;
        display: flex;
    justify-content: center;
    align-items: center;
    background-size:cover;
    overflow: "hidden";
    display: "flex";
    justify-content: "center";
    align-items: "center";
    background-position: center center;
  }
  
  .text-center {
    width: 100%;
    font-size: 1.75rem;
    color: white;
    margin: 0 auto;
    line-height: 1.5;
    animation: fadeIn 1s ease-in-out;

  }
  .Deconnect,.Menu {
    font-size: 1.75rem;
    margin: 0 auto;
    line-height: 1.5;
    font-weight: 300;
    cursor: pointer;

  }
 