/* articles.css */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.PlatformeArticle {
  position: relative;
  text-align: center;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center center; /* Ajoutez !important */
  background-size: cover;
}


.ArtcTitre {
  width: 70%;
  font-size: 3.75rem;
  color: white;
  margin: 0 auto;
  line-height: 1.5;
  animation: fadeIn 1s ease-in-out;
  font-weight: 300;
}
.Article-Containe {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
}
.article-list {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  padding: 80px 0;
}

.article-card {
  width: 540px;
  height: 437px;
  margin-bottom: 80px;
  box-shadow: 0 5px 30px -10px rgba(0, 0, 0, 0.2);
  background-color: #FFF;
}

.article-card-title {
  margin-top: 20px;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 300;
  cursor: pointer;
}
.article-card-title:hover {
  color: rgb(39, 116, 188);
}
.article-card-author {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #b3b3b3;
  font-size: 16px;
  font-weight: 300;

  cursor: pointer;
}
.article-card-author:hover {
  color: #000;
}
.article-card-author-icon {
  margin-right: 5px;
  font-size: 18px;
  color: #b3b3b3;
}

/*dialogue*/
.ArticleContai {
  padding: 40px;
}
.ART-titre {
  color: rgb(227, 216, 10);
  cursor: pointer;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 300;
}


.Artc-inf {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
  padding: 20px 2px 20px 2px;
  gap: 10px;
  margin-top: 5px;
}

.Art-Descrip {
  font-size: 16px;
  color: gray;
  font-weight: 400;
}

.Art-Descrip a {
  color: #e3d80a;
  text-decoration: none;
}

.Art-Descrip a:hover {
  color: #fdc900;
}

.artic-author-icon {
  color: #9b9898;
  font-size: 20px;
}
.articl-auteur {
  color: #9b9898;
  font-size: 15px;
  font-weight: 100;
}

.info-item {
  padding: 2px;
  font-size: 12px;
  margin: 2px;
  border: none;
  border-radius: 5%;
  color: #fff;
}
.partageArticle {
  display: flex;
  justify-content: flex-start;

  gap: 5px;
}
