@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.TitreAtelier{
  width: 70%;
  font-size: 60px; /* Equivalent à 60px si la base est 16px */
  color: white;
  margin: 0 auto;
  line-height: 1.2;
  animation: fadeIn 1s ease-in-out;
  font-weight: 300;

}

/* Autres styles existants */
.ImagePlatformeAtelier{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
}
.Add-Atelier{
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #F8F9FA;

}
.AddAtelier-container {
    width: 80%;
  
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-top: 70px;
    margin: 0px auto;
  }
  
  .left-Atelier {
    width: 33%;
    height: 100% !important;
  

  }
  
  .right-Atelier {
    width: 67%;
    height: 100% !important;
    padding: 50px 30px ;
    background-color: #fff;

  }
  
  .atelier_image{
    display: block;
    margin: 0 auto;
  }
.Atelier-Item{
  background-color: #fff;
  padding: 20px;

  margin-bottom:20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
  
  .left-Atelier img {
    width: 250px;
    height: 150px;
    border-radius: 5px;
  }
  
  .bublAtelier {
    font-size: 18px;
    margin-top: 10px;
    color: #000;
    font-weight: 300;
    line-height: 1.2;
    cursor: pointer;
    /* text-align: justify; */
  }
  .bublAtelier:hover{
    color: #043F74;
 
  }
  .AtelierTheme,.AtelierTheme,.AtelierTitre{
    width: 80%;
    margin: 0 auto;
text-align: center;
font-size: 1.5rem;
color: #000;
font-weight: 300;

  }
  .AtelierStatut,.AtelierHeure,.AtelierDate{
    text-align: center;
    font-size: 16px;
    color: gray;
    font-weight: 300;
    
      }
  
  .labelAtelier{
    font-size: 16;
    color: gray;
    margin-bottom: 15px;
  }

  
  .right-Atelier form {
    display: flex;
    flex-direction: column;
  }
  
  .inputAtelier {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    font-size: 14px;
    color: #535252;
    cursor: pointer;
    padding-left: 15px;
  }
  .TextareaAtelier{
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: #535252;
  
    cursor: pointer;
  
  }
  .LabelAtelier{
    margin: 10px 0;
  }
 .radio{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  color: gray;
 }
  .BoutonAtelier{
  display: block;
  margin: 0 auto;
  padding: 0.625rem; /* Equivalent à 10px */
  background-color: #FDC900;
  color: white;
  border: none;
  border-radius: 9px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  }
  
  .inputAtelier:focus,
  .TextareaAtelier:focus {
      border: 0.5px solid rgb(36, 233, 233); /* Ajoute une bordure fine de 1 pixel */
      outline: none; /* Supprime l'outline par défaut */
  }
  
  
  

 
  
 
  
  
  
  
  
