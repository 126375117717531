.ConsultAddFaq{
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Rubik", arial, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
    
}
.ConsultAddFaqContainer{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    gap: 50px;
}
.TitreFAQ{
    font-size: 40px;
    font-weight: 300;
    color: #000;
}
.QUESTION,.RES{
    width: 100%;
    height: 43px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    font-size: 14px;
    color: #535252;
    cursor: pointer;   
    padding: 0.375rem 0.75rem;

}

  
  .QUESTION:focus,  .RES:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  }
.RES{
height: 150px; 
}