.ConsultEvenement{
    width: 100%;
    margin: 0;
    padding: 0;
 

    
}
.ConsultEvenementContainer{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px auto;
}
.AccuEvenement{
    width: 50%;
    display: block;
    background-color: #0d345a;
    font-weight: bold;
    padding: 10px 0px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    line-height: 2;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;



}
