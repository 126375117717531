.ConsultSlide{
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Rubik", arial, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
 } 
.ConsultSlideContainer{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    gap: 20px;
 
}
.imgSlide{
    width: 150px !important;
}
.AccueilSlide{
  


         width: 40%;

    background-color:#0D345A;
    padding:10px 0px;
     text-align:center; 
     color: #e3d80a;
     cursor: pointer;
     line-height: 32px;
     text-decoration-style:solid ;
     font-size: 16px;
     font-weight: bold;
margin: 30px 0;
}
.DataSlider{
    width: 22%;
    border: 1px solid #ccc; 
    padding: 8px;        font-size: 12px;
        color: gray;
    
    }
      .HeaderSlider {
    
        border: 1px solid #ccc; 
        padding: 8px;        font-size: 10px;
        font-weight: bold;
        color: rgb(128, 128, 128);
      }
      /*edit Slider*/
      .edit-Slider{
        width: 100%;
        margin: 0;
        padding: 0;
      }
      .EditContainerSlider{
        width: 40%;
        margin: 0 auto;
      }
      .LabelEditSlider{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        color:rgb(51, 51, 51);
        font-weight: 700;
        margin-bottom: 5px;
      }
      .buttonsContainer {
        display: flex;
        justify-content: center;
        gap: 20px; /* Espace entre les boutons */
        margin-top: 20px;
      }
  .btnEditSlider, .btnAnnuleSlider {
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 4px;

  }
  
  .btnEditSlider {
    background-color: #337ab7;
    border-color: #2e6da4;
    color: white;
    
  }
  
  .btnAnnuleSlider {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
  }
.btnAnnuleSlider:hover{
    background-color: #ccc;
}
.btnEditSlider:hover {
    background-color: #2e6da4;

    
  }
  .btnEditSlider:focus {
    border:solid 2px  #000;

    
  }