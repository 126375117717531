.ConsultEvenement{
    width: 100%;
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
    
}
.ConsultEvenementContainer{
    width: 80%;
margin: 0 auto;
}
.buttonAccueilEvnt {
  width: 50%;
  height: 50px;
  display: block;
  margin: 40px auto;
  background-color: #0d345a;
  font-weight: bold;
  /* padding: 10px 100px; */
  text-align: center;
  color: #e3d80a;
  cursor: pointer;
  line-height: 32px;
  text-decoration: none;
  font-size: 16px;
  }
  
  .buttonAccueil:hover {
    color: #fdc900;
  }

.ListEvntAdm{
    width:100px;
    height:50px;
}

.DataEvnt{
width: 22%;
border: 1px solid #ccc; 
padding: 8px;    font-size: 12px;
    color: gray;

}
  .TableHeaderEvnt {

    border: 1px solid #ccc; 
    padding: 8px;    font-size: 10px;
    font-weight: bold;
    color: rgb(128, 128, 128);
  }
  

