.edit-Article {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
}

.EditContainer {
    width: 50%;
    height: 100vh;

    margin: 0 auto;

}
.FormContainerItem{
    display: flex;
    flex-direction: column;
}
.labelEdit{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color:rgb(51, 51, 51);
    font-weight: 700;
}
.inputView {
    width: 100%;
    height: 43px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    font-size: 14px;
    color: #535252;
    cursor: pointer;   
    padding: 0.375rem 0.75rem;
  }
  .editArt{
    width: 100%;
    height: 400px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    font-size: 14px;
    color: #535252;
    cursor: pointer;   
    padding: 0.375rem 0.75rem;
  }
  .inputView:focus,.editArt:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  }
  



.tox{
    width: 100%;
}


.Bouton-Edit-Article{
    display: flex;
    width: 50%;
margin: 0 auto;
gap: 20px;}

.imageEdit-Article {
    display: block;
    width: 30%;
    margin: 0 auto;
}


