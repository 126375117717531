.Site-Footer {
  width: 100%; /* Adjust container width as needed */
margin: 0;
padding: 0;
  background-color: #fff;
}

.footer-container {
  width: 80%; /* Adjust container width as needed */
  margin: 60px auto;
  /* padding: 0 20px; */
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns with equal width */
  gap: 10px; /* Adjust gap between columns as needed */
}

.footer-column {
  text-align: start; /* Align content to start in each column */
}
.footerTitre{
  font-weight: 300;
  color: #000;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
}
.FooterCooach{
  margin-bottom: 1rem;
  line-height: 1.7;

  font-size: 16px;
  color: rgb(128, 128, 128);
  text-align: left;
  cursor: pointer;
}

.footerPages ,.TounesConnect{
  text-decoration: none;
  color: rgb(227, 216, 10)
  ;
  cursor: pointer;

  font-size: 16px;
  line-height: 27px;
}
.footerPages:hover,.footer-style-social:hover,.footecontact:hover{
  color: rgb(253, 201, 0)
  ;
}
.footer-list-unstyled {
  padding-left: 0;
  list-style: none;
}
.FooterSalon{
  margin-bottom: 1rem;
  line-height: 27px;

  font-size: 18px;
  color: rgb(7, 7, 7);
  text-align: left;
  cursor: pointer;
}
.FooterSalon:hover{

  color: rgb(39, 84, 145);
}
.footer-contact  {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footecontact {
  text-decoration: none;
  color: #e3d80a;
  cursor: pointer;

  font-size: 16px;
  margin-left:10px ;

}







.footer-style-social {
  font-weight: 500;
  margin: 0 10px;
  color: #ECE455;
  cursor: pointer;

}

.Copyright{
  width: 80%;
  margin: 6rem auto;
  line-height: 1.7;
font-weight: 300;
  font-size: 16px;
  color: gray;
  text-align: left;
  display: block;
}

.footer-span {
  color: rgb(227, 216, 10);
  font-weight: 500;
  cursor: pointer;

}
@media (max-width: 992px) {
    
  .footer-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 4 columns with equal width */
    grid-template-rows: repeat(2, 1fr); /* 4 columns with equal width */

    gap: 30px; /* Adjust gap between columns as needed */
  }
  .Copyright{
    width: 80%;
    margin: 0px auto;
  
  }
  }
  @media (max-width: 480px) {
    
    .footer-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr); /* 4 columns with equal width */
      grid-template-rows: repeat(4, 1fr); /* 4 columns with equal width */
      gap: 10px; /* Adjust gap between columns as needed */
    }
    .Copyright{
      width: 80%;
      margin: 0px auto;
    
    }
    }


