.edit-Evenement {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
}


.FormContainerEvenement {
    width: 50%;
    height: 100vh;

    margin: 0 auto;

}

.labelIner{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color:rgb(51, 51, 51);
    font-weight: 700;
}

.InputEditInter{
    width: 100%;
    height: 200px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.InputEditInter:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.form-group-Evenement {
    width: 600px;
    margin-bottom: 15px;
}

.imageEdit-Article {
    display: block;
    width: 30%;
    margin: 0 auto;
}
.custom-ckeditor-text {
    color: #000;
}
.Bouton-Edit-Evenement {

     display: flex; 
    gap: 20px;
}
