.ConsultFAQ{
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Rubik", arial, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    color: gray;
    
}
.ConsultFAQContainer{
    width:40%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto 100px auto;
}
.AccueilFAQ{
    width: 100%;

    background-color:#0D345A;
    padding:10px 0px;
     text-align:center; 
     color: #fff;
     cursor: pointer;
     line-height: 32px;
     text-decoration-style:solid ;
     font-size: 16px;
     font-weight: bold;
margin: 30px 0;


}
