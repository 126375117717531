.barheaderC {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #275491;
}
.barheader-Container{
    width: 95%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.iconCom {
    font-size: inherit;
    color: #e3d80a;
    transition: color 0.3s; /* Smooth transition */
}
.mocompte-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s; /* Smooth transition */
}
.mocompte, .faq {
    margin: 0;
    color: #e3d80a;
    font-size: 16px;
    font-weight: 300;
    font-family: Arial, Helvetica, sans-serif;
    transition: color 0.3s; /* Smooth transition */
    
}
.faq {
    margin-left: 15px;
    cursor: pointer;
}
.mocompte-container:hover .mocompte, .mocompte-container:hover .iconCom {
    color: rgb(253, 201, 0);
}
.faq:hover {
    color: rgb(253, 201, 0);
}
.dropdown-menu {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    z-index: 1000;
}
.selected:focus {
    background-color: rgb(253, 201, 0) !important;
    color: white !important;
    outline: none;
}
