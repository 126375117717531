.MenuA {
    width: 100%;
    height: 100vh;
    background-color: #0B355C;
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .containeMenu {
    width: 35%;
    height: 200px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
   
.AdminT{
    font-family: 'Times New Roman', Times, serif;
text-align: center;
font-size: 2rem;
font-weight: 700;
}
.ButtonMenu{
    font-family: 'Times New Roman', Times, serif;

    margin: auto;
    font-size: 20px;
    text-align: center;
    color: #fff;
    background-color: #0B355C;
    width: 100%;
    height: 40px;
    padding: 8px 0px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 300;

}

