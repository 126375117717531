@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  
  .PlatformeForget{
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 230px; /* Hauteur de la section */
    background-size: cover; /* Couvrir toute la section */
    overflow: hidden;
    text-align: center;
  background-position: center center;
    
  }
  
  
  
  
  .TitreForget{
    width: 50%;
    font-size: 3.75rem; /* Equivalent to 60px if base is 16px */
    color: white;
    margin: 0 auto;
    line-height: 1.2;
    animation: fadeIn 1s ease-in-out;
    font-weight: 300;
  }
  
  
  
  .containerForget {
    width: 100%;
    height: 500px;
  }
  
  .Form-Forget {
    position: absolute;
    width: 45%; 
    left: 50%;
    transform: translate(-50%, -5%);
    background-color: #FFFFFF;
    padding: 3.125rem; /* Equivalent à 50px */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .labelForget {
    width: 100%;
    font-size: 1rem; /* Equivalent à 16px */
    line-height: 2; /* Equivalent à 32px si la base est 16px */
    color: #e3d80a;
  }
  .ConnectForget {
    width: 100%;
    font-size: 16px; /* Equivalent à 16px */
    line-height: 32px; /* Equivalent à 32px si la base est 16px */
    color: rgb(128, 128, 128);
    font-weight: 300;
  }
  .input-Forget {
    width: 100%; 
  height: 2.5rem; /* Equivalent à 40px */
  font-weight: 400;
  margin-bottom: 0.9375rem; 
  font-size: 16px;
  background-color: transparent;
  }
  .input {
    width: 100%; 
    height: 2.5rem; /* Equivalent à 40px */
    border: 1px solid #ccc;
    color:rgb(73, 80, 87)  ;
    font-weight: 400;
    font-size: 16px;
    background-color: transparent;
  }
  .input:focus {
    border: solid 1px transparent;
    outline-color: rgb(36, 233, 233);
    outline-style: solid;
    outline-width: 0.5px; /* Définir l'épaisseur de la bordure */
    outline-offset: 0;
    background-color: transparent;
  }
  .input-Forget:focus {
    outline-color: rgb(36, 233, 233);
    outline-style: solid;
    outline-width: 1.5px; /* Définir l'épaisseur de la bordure */
    outline-offset: 0;
  }
  
  .Connecter {
    width: 40%; /* Specify the width of the button */
    padding: 0.625rem; /* Equivalent à 10px */
    background-color: #FDC900;
    color: white;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .Connecter:hover {
    color: #000;
    background: none;
    border: 2px solid #275491;
  }
  
  .ImagePlatforme {
    margin-bottom: 1.25rem; /* Equivalent à 20px */
  }
  
  .FormSection {
    margin-bottom: 2.5rem; /* Equivalent à 40px */
  }
  
  .NewsletterSection {
    margin-bottom: 1.875rem; /* Equivalent à 30px */
  }
  
  @media (max-width: 992px) {
    .TitreLogin {
      font-size: 2.5rem; /* Equivalent à 40px */
      line-height: 1.8; /* Adjust line height as necessary */
    }
    .input-Login {
      width: 100%; /* Ensure it remains 100% */
    }
  }
  
  @media (max-width: 768px) {
    .input-Login {
      width: 100%; /* Ensure it remains 100% */
    }
  }
  
  @media (max-width: 576px) {
    .containerForget {
      width: 100%;
      height: 400px;
    }
    .Form-Forget {
      width: 80%; 
    
    }
    .TitreForget {
      width: 90%;
      padding-top: 1.875rem; /* Equivalent à 30px */
    }
    .input-Forget {
      width: 100%; /* Ensure it remains 100% */
    }
  }
  