.ConsultCoch {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .ConsultCochcontainer {
    width: 90%;
    margin: 0 auto;
  }
  
  .TableCoch {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }
  
  .TableHeaderC {
    font-family: Arial, Helvetica, sans-serif;
    border: 1px solid #ccc; 
    padding: 6px;
    
    font-size: 13px;
    font-weight: bold;
    color: rgb(128, 128, 128);
  }
  
  .TableDataC {
    width: 20%;
    border: 1px solid #ccc; 
    padding: 8px;
        font-weight: 300;
    font-size: 12PX;
    color: rgb(128, 128, 128);

  }
  

  
  .TableDataimgC {
    width: 100px;
  }
  
  .TableDataText {
    white-space: pre-line;
    text-align: start;
  }
  
  .DataAct {
    display: flex;
    
  }
  
  .IconData {
    /* margin:  0 20px; */
margin: 6px;
padding: 0;
    font-size: 18px;
    color: black;
  }
  
  .IconSend {
    /* margin:  0 20px; */

    font-size: 18px;
    color: black;
  }
  