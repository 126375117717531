/* Définition des variables CSS personnalisées */
:root {
  --marquee-width: 80vw; /* Largeur du conteneur du marquee */
  --marquee-height: 20vh; /* Hauteur du conteneur du marquee */
  --marquee-elements: 12; /* Nombre total d'éléments dans le marquee */
  --marquee-elements-displayed: 5; /* Nombre d'éléments affichés à la fois dans le marquee */
  --marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed)); /* Largeur de chaque élément dans le marquee */
  --marquee-animation-duration: calc(var(--marquee-elements) * 3s); /* Durée totale de l'animation */
}
.Partenaire{
  width: 100%;
margin: 0;
padding: 0;
}
/* Styles pour le conteneur principal */
.form-groupe {
  width: 80%; /* Largeur du formulaire groupe */
  margin: 0 auto; /* Centrer horizontalement */
}
.PartenaireTitre{
  width: 100%;
  text-align: center;
  font-size: 36px;
  color: #275491;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  margin: 0px auto ;
}
/* Styles pour le conteneur du marquee */
.marquee {
  width: 100%; /* Largeur totale */
  overflow: hidden; /* Masquer le contenu débordant */
  color: #5a5454; /* Couleur du texte */
  position: relative; /* Position relative pour les éléments enfants absolus */
}



/* Styles pour le contenu du marquee */
.marquee-content {
  list-style: none; /* Supprimer les puces de liste */
  height: 100%; /* Hauteur totale */
  display: flex; /* Affichage en mode flex */
  animation: scrolling var(--marquee-animation-duration) linear infinite; /* Animation du défilement */
  margin: 0; /* Marge zéro */
  /* margin: 30px; */
}

/* Styles pour chaque élément de la liste */
.marquee-item {
  display: flex; /* Affichage en mode flex */
  justify-content: center; /* Centrer horizontalement */
  align-items: center; /* Centrer verticalement */
  flex-shrink: 0; /* Ne pas rétrécir */
  width: var(--marquee-element-width); /* Largeur calculée de chaque élément */
  max-height: 100%; /* Hauteur maximale */
  font-size: calc(var(--marquee-height) * 3 / 4); /* Taille de police */
  white-space: nowrap; /* Ne pas passer à la ligne */
  margin-right: 0; /* Marge à droite */
  padding-right: 0; /* Rembourrage à droite */
  list-style-type: none; /* Type de puce de liste */
}

/* Styles pour les images à l'intérieur des éléments */
.marquee-img{
  width: 70%; /* Largeur de l'image */
  border: 1px solid #e9e9e9; /* Bordure */
  height: 67%; 

}

/* Définition de l'animation du défilement */
@keyframes scrolling {
  0% { transform: translateX(0); } /* Début de l'animation */
  100% { transform: translateX(calc(-192vw)) } /* Fin de l'animation */
}
@media (max-width:727PX) {

  :root {

    --marquee-elements-displayed: 4; /* Nombre d'éléments affichés à la fois dans le marquee */
    
  }
}
@media (max-width:727PX) {

  :root {

    --marquee-elements-displayed: 4; /* Nombre d'éléments affichés à la fois dans le marquee */
    
  }
}
@media (max-width:581PX) {

  :root {

    --marquee-elements-displayed: 3; /* Nombre d'éléments affichés à la fois dans le marquee */
    
  }
}