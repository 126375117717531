@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.PlatformeArticle {
  position: relative;
  text-align: center;
  min-height: 250px;
  height: 250px;
      display: flex;
  justify-content: center;
  align-items: center;
  background-size:cover;
  overflow: "hidden";
  display: "flex";
  justify-content: "center";
  align-items: "center";
  background-position: center center;

}

.Coach_Titre {
  width: 100%;
  font-size: 60px;
  color: white;
  margin: 0 auto;
  line-height: 1.5;
  animation: fadeIn 1s ease-in-out;
  font-weight: 300;

}
.Formulaire {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
}
.Formulaire-container {
  width: 90%;

  display: flex;
  justify-content: center;
  margin: 0 auto;
  gap: 30px;
  padding: 50px;
}
.formulaire-left {
  width: 33%;
  text-align: center;
}

.formulaire-right {
  width: 67%;
  background-color: #fff !important;
  padding: 50px 20px;

  margin-bottom: 70px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.formulaire-left-img {
  border-radius: 50%;
  padding: 20px;

  width: 150px;
}
.nomCOACH {
  text-align: center;

  font-size: 20px;
  font-weight: 300;
  cursor: pointer;
}
.nomCOACH:hover{
  color: #275491;
}
.COACH {
  text-align: center;

  font-size: 16px;
  font-weight: 300;
  color: rgb(153, 153, 153);
}
.bioCOACHtitre {
  text-align: center;
    margin:  0 auto;
  font-size: 16px;
  color: rgb(128, 128, 128);
  font-weight: 300;
}
.bioCOACH {
  font-size: 16px;
  color: rgb(128, 128, 128);
  font-weight: 300;
  text-align: justify;
  margin: 0;
  padding: 0px;
}
.social-icons {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.iconCOACH {
  color: rgb(228, 228, 5);
}
.formulairCOACH {
  font-size: 24px;
  color: #000;
  font-weight: 300;
  padding: 50px 0;
}

.formulaire-Coach {
  background-color: #fff;
  padding: 20px;
height: 500px;
  margin-bottom: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.CHECK {
  width: 20px;
}

.TITREcoach {
  /* display: flex;
    justify-content: flex-start;
    align-items: center; */
  font-size: 16px;
  color: rgb(148, 147, 147);
}
.formulaire-right h5 {
  font-size: 20px;
  color: #000;
  font-family: "Rubik", arial, sans-serif;
  font-weight: 400;
  margin-bottom: 40px;
  text-decoration: underline;
}
.inputCoach,
.gouvernorat,.domaine {
  border: solid 1px #ccc;
  margin: 20px 0;
  width: 100%;
  height: 40px;

  background-color: transparent;
}
.domaineCoach{
  width: 100% !important;
}
.react-dropdown-select-content {
  display: flex;
justify-content: flex-end;}
.inputCoach:focus,
.gouvernorat:focus {
  border: solid 0.5px transparent;

  outline-color: rgb(36, 233, 233);
  outline-style: solid;

  outline-width: 0.5px;
  outline-offset: 0;
}
.checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}
.AddCoach {
  background-color: rgb(253, 201, 0);

  padding: 0.375rem 0.75rem;
  font-weight: 400;
  text-align: center;
  border-radius: 10px;
  font-size: 1rem;
  color: #fff;
  border: 2px solid transparent;
  cursor: pointer;
  margin: 30px auto;
}
.AddCoach:hover {
  background-color: transparent;
  color: #000;
  border: solid 2px blue;
}
.filecoach {
  margin: 10px;
  font-size: 15px;
  color: gray;
}
.required-label::after {
  content: "*";
  color: rgb(241, 130, 130);
  font-size: 15px;
  margin-left: 5px;
}
.error-message{
  color:rgb(238, 66, 66) ;
  font-size: 13px;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}
.password-icon {
  position: absolute;
  right: 10px; /* Positionnement à droite de l'input */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991PX) {
  .formulaire-left {
    width:  45%    ;
  }
  
  .formulaire-right {
    width:  55%    ;
   
  }
  }
  @media (max-width: 767PX) {
    .Formulaire-container {
    width: 98%;
      display: flex;
flex-direction: column-reverse;   
    }
    .formulaire-left {
      width:  100%    ;
    }
    
    .formulaire-right {
      width:  100%    ;
     
    }
    }