@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.PlatformeArticle-Add {
  position: relative;
  text-align: center;
  min-height: 250px;
  height: 250px;
      display: flex;
  justify-content: center;
  align-items: center;
  background-size:cover;
  overflow: "hidden";
  display: "flex";
  justify-content: "center";
  align-items: "center";
  background-position: center center;

         
}

.ArtcTitre {
  width: 80%;
  font-size: 60px;
  color: white;
  margin: 0 auto;
  line-height: 1.5;
  animation: fadeIn 1s ease-in-out;
  font-weight: 300;

}

.ajouterArtc {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: rgb(248, 249, 250);
}

.ajouterArtcContainer {
  width: 80%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 0px auto;
  padding-top: 80px;
}

.left-Article {
  width: 33%;
  height: 100% !important;
 
}
.Article-Item{
  background-color: #fff;
  padding: 20px;

  margin-bottom:20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
  
.left-ArticlePartager {
  margin-bottom: 20px;
  color: rgb(39, 84, 145);
  font-size: 20px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
}

.left-Article-img {
  width: 236px;
  height: 125px;
}

.titreART {
  font-size: 20px;
  margin-top: 10px;
  color: rgb(227, 216, 10);
  font-weight: 300;
  line-height: 24px;
  cursor: pointer;
}
.titreART:hover {
  color: rgb(207, 197, 10);
}

.auteurART {
  font-size: 16px;
  margin-top: 5px;
  font-weight: 300;
  line-height: 32px;
  color: rgb(128, 128, 128);
}

/*Right*/
.right-Article {
  background-color: #fff;

  width: 67%;
  height: 100% !important;
  padding: 80px 27px;
  margin: 0;
}

.FormulaireCoach {
  margin-bottom: 40px;
  font-size: 23px;
  color: #000;
  font-weight: 300;
}

/* .right-Article form {
  display: flex;
  flex-direction: column;
} */
.AddArt{
  width: 100%;
  height: 400px;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  font-size: 1rem;
  color: #535252;
  cursor: pointer;
  padding: 0.375rem 0.75rem; 
}
.inputArticle {
  width: 100%;
  height: 43px;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  font-size: 1rem;
  color: #535252;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
}

/* .tox {
  width: 80%;
  border-radius: 0;
  border: 1px solid #ced4da;
  margin: 0;
} */

.LabelArticle {
  margin: 10px 0;
  font-size: 16px;
  color: gray;
  line-height: 32px;
}
.FileArticle {
  margin: 10px;
  font-size: 15px;
  color: gray;
}
.BoutonArticle {
  background-color: rgb(253, 201, 0);

  padding: 0.375rem 0.75rem;
  font-weight: 400;
  text-align: center;
  border-radius: 10px;
  font-size: 1rem;
  color: #fff;
  border: 2px solid transparent;
  cursor: pointer;
}
.BoutonArticle:hover {
  color: #000;
  border: 2px solid blue;
  background-color: transparent;
}
.inputArticle:focus,
.TextareaArticle:focus,
.FileArticle:focus,.AddArt:focus {
  border: solid 1px transparent;
  outline-color: rgb(36, 233, 233);
  outline-style: solid; /* Set the outline style to solid */
  outline-offset: 0; /* Set the outline width to 1px */
}

/*dialogue*/
.ArticleContai {
  padding: 40px;
}
.ART-titre {
  color: rgb(227, 216, 10);
  cursor: pointer;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 300;
}
.textARTDialo {
  color: gray;
  font-size: 16px !important;
}

.Artc-inf {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
  padding: 20px 2px 20px 2px;
  margin-top: 5px;
}

.Art-Descrip {
  font-size: 16px;
  color: gray;
  font-weight: 400;
}

.Art-Descrip a {
  color: #e3d80a;
  text-decoration: none;
}

.Art-Descrip a:hover {
  color: #fdc900;
}

.info-item {
  padding: 2px;
  font-size: 14px;
  margin: 2px;
  border: none;
  border-radius: 5%;
  color: #fff;
}
.partageArticle {
  display: flex;
  justify-content: flex-start;

  gap: 5px;
}
@media (max-width: 992PX) {
  .PlatformeArticle-Add {
    height: 210px;
  }
  .ArtcTitre{
    width: 80%;
    font-size: 3.2rem;
  }
  .left-Article {
    width:  45%    ;
  }
  
  .right-Article {
    width:  55%    ;
   
  }
  }
  @media (max-width: 767PX) {
    .PlatformeArticle-Add {
    height: 180px;
  }
  .ArtcTitre{
    width: 80%;
    font-size: 2.5rem;
  }
    .ajouterArtcContainer {
    
      display: flex;
flex-direction: column-reverse;   
    }
    .left-Article {
      width:  100%    ;
    }
    
    .right-Article {
      width:  100%    ;
     
    }
    }