.ConsultInter {
    width: 100%;
    margin: 0;
    padding: 0;
}


.ConsultInterContainer {
    width: 70%;

    margin: 0PX auto;

}
.AccueilInter{
    width: 60%;
    height: 50px;
    display: block;
    margin: 10px auto;
    background-color: #0d345a;
    font-weight: bold;
    text-align: center;
    color: #e3d80a;
    cursor: pointer;
    line-height: 32px;
    text-decoration: none;
    font-size: 16px;
}
.DataInter{
    width: 22%;
    border: 1px solid #ccc; 
    padding: 8px;        font-size: 12px;
        color: gray;
    
    }
      .HeaderInter {
    
        border: 1px solid #ccc; 
        padding: 8px;        font-size: 10px;
        font-weight: bold;
        color: rgb(128, 128, 128);
      }